// import syncUpPlatformRequest from '../repositories/syncupPlatformRequest';


const cmsPageRepository = {
//   getCmsResult: async (url) => {
//     return await syncUpPlatformRequest({
//      url:url
//     });
//   }

  getCmsResult: async ({url}) => {
    console.log(url,"repo url")

    const response = await fetch(url);

    console.log(response,"response")
    if (response.ok) {
      const responseText = await response.text();
      if (responseText) {
        return JSON.parse(responseText);
      }
      return;
    }
    throw Error(response.status);
  }

};

export default cmsPageRepository;
