import simulatorRepository from '../repositories/simulatorRepository';
import assert from 'assert';
import { APP_MOBILE } from '../utils/app_constants';

const simulatorService = {
  getSimulators: async ({ syncUpAppName }) => {
    return await simulatorRepository.getSimulators({ syncUpAppName });
  },
  getIotSimulators: async ({ syncUpAppName, pageNumber, pageSize }) => {
    switch (syncUpAppName) {
      case APP_MOBILE.DRIVE:
        const results = await simulatorRepository.getIotSimulators({
          syncUpAppName,
          pageNumber,
          pageSize
        });

        const formattedList = results?.deviceList
          .map(d => ({
            ...d,
            deviceId: d.id,
            hardwareId: d.id
          }))
          .sort((a, b) => (a.statusAt < b.statusAt ? 1 : -1));
        return { ...results, deviceList: formattedList };
      default:
        return await simulatorRepository.getIotSimulators({ syncUpAppName, pageNumber, pageSize });
    }
  },
  addSimulator: async ({ simulator, syncUpAppName }) => {
    return await simulatorRepository.addSimulator({ simulator, syncUpAppName });
  },
  plugInDriveSimulator: async ({
    userId,
    deviceId,
    simulator,
    syncUpAppName
  }) => {
    return await simulatorRepository.plugInDriveSimulator({
      userId,
      deviceId,
      simulator,
      syncUpAppName
    });
  },
  addIotSimulator: async ({ simulator, deviceId, syncUpAppName }) => {
    return await simulatorRepository.addIotSimulator({
      simulator,
      deviceId,
      syncUpAppName
    });
  },
  updateWatchSimulatorStepCount: async ({ deviceId, syncUpAppName, body }) => {
    return await simulatorRepository.updateWatchSimulatorStepCount({
      deviceId,
      syncUpAppName,
      body
    });
  },
  saveSimulator: async ({ simulator, syncUpAppName }) => {
    assert(simulator, 'simulator is required');
    await simulatorRepository.saveSimulator({ simulator, syncUpAppName });
  },
  saveIotSimulator: async ({ simulator, syncUpAppName }) => {
    assert(simulator, 'simulator is required');
    await simulatorRepository.saveSimulator({ simulator, syncUpAppName });
  },
  deleteSimulator: async ({ id, syncUpAppName }) => {
    return await simulatorRepository.deleteSimulator({ id, syncUpAppName });
  },
  deleteIotSimulator: async (id, syncUpAppName) => {
    return await simulatorRepository.deleteIotSimulator({ id, syncUpAppName });
  },

  createSimulatorDeviceEvent: async ({ hardwareId, syncUpAppName, body }) => {
    return await simulatorRepository.createSimulatorDeviceEvent({
      hardwareId,
      syncUpAppName,
      body
    });
  }
};

export default simulatorService;
