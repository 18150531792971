import home from '../static/assets/icons/leftpanel/home.svg';
import acPlug from '../static/assets/icons/leftpanel/ac_plug.svg';
import BarChart from '../static/assets/icons/leftpanel/Bar_Chart.svg';
import Bulk from '../static/assets/icons/leftpanel/Bulk.svg';
import Cloud from '../static/assets/icons/leftpanel/Cloud.svg';
import Devices from '../static/assets/icons/leftpanel/Devices.svg';
import DPI from '../static/assets/icons/leftpanel/DPI.svg';
import Mobile from '../static/assets/icons/leftpanel/Mobile.svg';
import School_Mode from '../static/assets/icons/leftpanel/School_Mode.svg';
import Simulator from '../static/assets/icons/leftpanel/Vector.svg';
import Auth_user from '../static/assets/icons/leftpanel/auth_user.svg';
import Odm_tracker from '../static/assets/icons/leftpanel/odm_tracker.svg';
import Odm_kidswatch from '../static/assets/icons/leftpanel/odm_kidswatch.svg';
import Media from '../static/assets/icons/leftpanel/media.svg';
import Help from '../static/assets/icons/leftpanel/help.svg';
import Dashboard from '../static/assets/icons/leftpanel/dashboard.svg';
import OnboardingIcon from '../static/assets/icons/leftpanel/OnboardingIcon.svg';
import Bulk_notification from '../static/assets/icons/leftpanel/submenus/bulk_notification.svg';
import Bulk_recomission from '../static/assets/icons/leftpanel/submenus/bulk_recomission.svg';
import Bulk_unclaim from '../static/assets/icons/leftpanel/submenus/bulk_unclaim.svg';
import Business_metric from '../static/assets/icons/leftpanel/submenus/business_metric.svg';
import Dashboard_icon from '../static/assets/icons/leftpanel/submenus/dashboard.svg';
import Error_metric from '../static/assets/icons/leftpanel/submenus/error_metric.svg';
import Instabug_metric from '../static/assets/icons/leftpanel/submenus/instabug_metric.svg';
import Odm_tracker_icon from '../static/assets/icons/leftpanel/submenus/odm_tracker.svg';
import Pier_ticketing from '../static/assets/icons/leftpanel/submenus/pier_ticketing.svg';
import Test_dashboard from '../static/assets/icons/leftpanel/submenus/test_dashboard.svg';
import Validate_device from '../static/assets/icons/leftpanel/submenus/validate_device.svg';
import Operations from '../static/assets/icons/leftpanel/operations.svg';

// actions

export const SEARCH_USERS_REQUEST = 'SEARCH_USERS_REQUEST';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_ERROR = 'SEARCH_USERS_ERROR';

export const USER_THINGS_REQUEST = 'USER_THINGS_REQUEST';
export const USER_THINGS_SUCCESS = 'USER_THINGS_SUCCESS';
export const USER_THINGS_ERROR = 'USER_THINGS_ERROR';

export const DEVICES_USER_REQUEST = 'DEVICES_USER_REQUEST';
export const DEVICES_USER_SUCCESS = 'DEVICES_USER_SUCCESS';
export const DEVICES_USER_ERROR = 'DEVICES_USER_ERROR';
export const DEVICES_USER_INITIAL_STATE = 'DEVICES_USER_INITIAL_STATE';

export const NOTIFICATION_USER_REQUEST = 'NOTIFICATION_USER_REQUEST';
export const NOTIFICATION_USER_SUCCESS = 'NOTIFICATION_USER_SUCCESS';
export const NOTIFICATION_USER_ERROR = 'NOTIFICATION_USER_ERROR';
export const NOTIFICATION_USER_INITIAL_STATE =
  'NOTIFICATION_USER_INITIAL_STATE';

export const DEVICE_SENSOR_HISTORY_REQUEST = 'DEVICE_SENSOR_HISTORY_REQUEST';
export const DEVICE_SENSOR_HISTORY_SUCCESS = 'DEVICE_SENSOR_HISTORY_SUCCESS';
export const DEVICE_SENSOR_HISTORY_ERROR = 'DEVICE_SENSOR_HISTORY_ERROR';

export const LOCATION_SENSOR_HISTORY_REQUEST =
  'LOCATION_SENSOR_HISTORY_REQUEST';
export const LOCATION_SENSOR_HISTORY_SUCCESS =
  'LOCATION_SENSOR_HISTORY_SUCCESS';
export const LOCATION_SENSOR_HISTORY_ERROR = 'LOCATION_SENSOR_HISTORY_ERROR';

export const ACTIVITY_SENSOR_HISTORY_REQUEST =
  'ACTIVITY_SENSOR_HISTORY_REQUEST';
export const ACTIVITY_SENSOR_HISTORY_SUCCESS =
  'ACTIVITY_SENSOR_HISTORY_SUCCESS';
export const ACTIVITY_SENSOR_HISTORY_ERROR = 'ACTIVITY_SENSOR_HISTORY_ERROR';

export const TEMPERATURE_SENSOR_HISTORY_REQUEST =
  'TEMPERATURE_SENSOR_HISTORY_REQUEST';
export const TEMPERATURE_SENSOR_HISTORY_SUCCESS =
  'TEMPERATURE_SENSOR_HISTORY_SUCCESS';
export const TEMPERATURE_SENSOR_HISTORY_ERROR =
  'TEMPERATURE_SENSOR_HISTORY_ERROR';

export const REMOVE_DEVICE_REQUEST = 'REMOVE_DEVICE_REQUEST';
export const REMOVE_DEVICE_SUCCESS = 'REMOVE_DEVICE_SUCCESS';
export const REMOVE_DEVICE_ERROR = 'REMOVE_DEVICE_ERROR';

export const ADD_DEVICE_REQUEST = 'ADD_DEVICE_REQUEST';
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
export const ADD_DEVICE_ERROR = 'ADD_DEVICE_ERROR';

export const HEALTH_CHECK_DEVICE_INITIAL_STATE =
  'HEALTH_CHECK_DEVICE_INITIAL_STATE';
export const HEALTH_CHECK_DEVICE_REQUEST = 'HEALTH_CHECK_DEVICE_REQUEST';
export const HEALTH_CHECK_DEVICE_SUCCESS = 'HEALTH_CHECK_DEVICE_SUCCESS';
export const HEALTH_CHECK_DEVICE_ERROR = 'HEALTH_CHECK_DEVICE_ERROR';

export const STORAGE_TYPES = {
  SESSION: 'sessionStorage',
  LOCAL: 'localStorage'
};

export const KEY_CODES = {
  ENTER: 13
};

export const PET_TYPES = {
  DOG: 'DOG',
  CAT: 'CAT',
  OTHER: 'OTHER'
};

export const PET_GENDERS = {
  DOG: 'MALE',
  CAT: 'FEMALE',
  OTHER: 'OTHER'
};

export const PENNYSTONE_GENDERS = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER'
};

export const THING_TYPES = {
  PET: 'PET',
  VEHICLE: 'VEHICLE'
};

export const APP_PLATFORM = {
  IOS: 'iOS',
  ANDROID: 'Android'
};

export const APP_MOBILE = {
  PETS: 'PETS',
  TRACKER: 'TRACKER',
  WATCH: 'KIDSWATCH',
  DRIVE: 'DRIVE',
  FAMILYLOCATION: 'FAMILYLOCATION',
};

export const MISC_PRODUCTS = {
  QA_DASHBOARD: 'QADASHBOARD',
  INTAKE_FORMS: 'INTAKE FORM'
};

export const IOT_PRODUCTS = [APP_MOBILE.TRACKER, APP_MOBILE.WATCH];

export const TRACKER_TYPES = {
  TAG: 'TAG'
};

export const THING_TYPE_TRACKER = {
  TRACKER: 'TRACKER'
};

export const THING_TYPE_PET = {
  PET: 'PET'
};

export const THING_TYPE_WATCH = {
  KIDSWATCH: 'KIDSWATCH'
};

export const THING_TYPE_DRIVE = {
  DRIVE: 'DRIVE'
};

export const THING_TYPE_FAMILYLOCATION = {
  FAMILYLOCATION: 'PHONE'
};

export const APP_PLATFORM_APPTENTIVE = {
  IOS: 'PETS_IOS',
  ANDROID: 'PETS_ANDROID'
};

export const ANALYTICS_DAYS_RANGE = {
  ONE_DAY: 24,
  WEEK: 7 * 24,
  BIWEEKLY: 14 * 24,
  MONTH: 30 * 24,
  TWO_MONTHS: 60 * 24,
  THREE_MONTHS: 90 * 24,
  LAUNCH: 'Since Launch'
};

export const DATA_VIEW_TYPES = {
  LIST: 'List',
  MAP: 'Map',
  GRID: 'Grid'
};

export const MAP_DEFAULT_ZOOM_LEVEL = 17;

export const DATE_TIME_FORMATS = {
  LONG_DATE_TIME_SECOND: 'dddd, MMMM YYYY, h:mm:ss A z',
  LONG_DATE_DAY_TIME_SECOND: 'dddd, MMMM Do YYYY, h:mm:ss a z',
  LONG_DATE_TIME: 'LLLL z',
  LONG_DATE: 'dddd LL'
};
export const TIME_ZONES = {
  DEFAULT_TIMEZONE: 'America/Los_Angeles'
};

export const COORDINATES = {
  BELLEVUE: {
    lat: 47.613701,
    lng: -122.190933
  }
};

export const FIRMWARE_DOWNLOAD_STATUS = {
  SUCCEEDED: 'SUCCEEDED',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED'
};

export const IOT_ASSET_TYPES = {
  FIRMWARE: 'FIRMWARE',
  MEDIA: 'MEDIA'
};

export const SEARCH_KEYS = {
  EMAIL: 'email',
  IMEI: 'imei',
  MSISDN: 'msisdn',
  USERID: 'userId',
  FULL_NAME: 'fullname',
  PHONE_NUMBER: 'phoneNumber',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  FIRST_LAST_NAME: 'firstLastName'
};

export const DEVICE_SENSOR_ERRORS = {
  NO_ERROR: 'No error',
  LOW_BATTERY_POWER: 'Low battery power',
  EXTERNAL_POWER_SUPPLY_OFF: 'External Power supply is off',
  GPS_MODULE_FAILURE: ' GPS failure',
  LOW_RECEIVED_SIGNAL_STRENGTH: 'Signal strength is weak',
  OUT_OF_MEMORY: 'Device is out of memory',
  SMS_FAILURE: 'SMS failure',
  IP_CONNECTIVITY_FAILURE: 'IP connectivity failure',
  PERIPHERAL_MALFUNCTION: 'Peripheral malfunction'
};

export const HTTP_ERRORS = {
  '401': 'You are unauthorized to access this resource.',
  '403':
    'You do not have sufficient privileges. Please check with your Administrator.',
  '404': 'Not found',
  unauthorized_client: 'You are unauthorized to access this application.',
  access_denied:
    'You do not have sufficient privileges. Please check with your Administrator.',
  temporarily_unavailable:
    'Authorization server is temporarily unavailable, please check back later.',
  login_required: 'Please try to login again.'
};

export const STORAGE_KEYS = {
  OKTA_ID_TOKEN: 'id-token',
  OKTA_USER_INFO: 'okta-user-info',
  USER_TIMEZONE: 'user-timezone',
  OKTA_USER_GROUPS: 'Groups',
  OKTA_USER_PLATFRM_GROUPS: 'ThingsPlatformGroups',
  OKTA_USER_FIRSTNAME: 'given_name',
  OKTA_USER_LASTNAME: 'family_name',
  INTENDED_URL: 'intended-url',
  USER_TABS: 'user-tabs',
  USER_SEARCH_INPUT: 'user-search-input',
  SEARCH_TYPE: 'search-type'
};

export const SENSOR_TYPES = {
  DEVICE: 'DEVICE',
  LOCATION: 'LOCATION',
  TEMPERATURE: 'TEMPERATURE',
  ACTIVITY: 'ACTIVITY',
  LIGHT: 'LIGHT',
  BUZZER: 'BUZZER',
  WLAN: 'WLAN',
  LOSTMODE: 'LOSTMODE',
  CELL: 'CELL'
};

export const LOG_TYPE = {
  HISTORY: 'HISTORY',
  STATUS: 'STATUS'
};

export const SENSOR_HISTORY_QUERY_KEYS = {
  CONTINUATION_TOKEN: 'continuationToken',
  START_DATE_TIME: 'start-date-time',
  END_DATE_TIME: 'end-date-time',
  PAGE: 'page',
  DATA_COUNT: 'size'
};

export const DATE_IN_TIME_ZONE = {
  USER: 'USER',
  CARE: 'CARE',
  SYSTEM: 'SYSTEM',
  CARE_AND_USER: 'CARE_USER'
};

export const SUA_ROLES = {
  PO: 'SyncUpPOs',
  ADMIN: 'SyncUpAdmins',
  CARE: 'SyncUpCare',
  PETS_ADMIN: 'SyncUpPetsAdmins',
  PETS_DEV: 'SyncUpPetsDevs',
  PETS_PO: 'SyncUpPetsPOs',
  TRACKER_ADMIN: 'SyncUpTrackerAdmins',
  TRACKER_DEV: 'SyncUpTrackerDevs',
  TRACKER_PO: 'SyncUpTrackerPOs',
  WATCH_ADMIN: 'SyncUpWatchAdmins',
  WATCH_DEV: 'SyncUpWatchDevs',
  WATCH_PO: 'SyncUpWatchPOs',
  DRIVE_ADMIN: 'SyncUpDriveAdmins',
  DRIVE_DEV: 'SyncUpDriveDevs',
  DRIVE_PO: 'SyncUpDrivePOs',
  FAMILYLOCATION_ADMIN: 'SyncUpFamilyLocationAdmins',
  FAMILYLOCATION_DEV: 'SyncUpFamilyLocationDevs',
  FAMILYLOCATION_PO: 'SyncUpFamilyLocationPOs',
  PETS_FIRMWARE_SUPPORT: 'SyncUpFirmwareSupportPets',
  TRACKER_FIRMWARE_SUPPORT: 'SyncUpFirmwareSupportTracker',
  WATCH_FIRMWARE_SUPPORT: 'SyncUpFirmwareSupportWatch',
  DRIVE_FIRMWARE_SUPPORT: 'SyncUpFirmwareSupportDrive',
  FIRMWARE_SUPPORT: 'SyncUpFirmwareSupport',
  SUPPLY_CHAIN: 'SyncUpPetsSupplyChain',
  ODM_KIDSWATCH: 'SyncUpOdmKidswatch',
  ODM_TRACKER: 'SyncUpOdmTracker',
  TAG_COMMERCIAL_USER: 'SyncUpTagCommercialUsers',
  TAG_COMMERCIAL_ADMIN: 'SyncUpTagCommercialAdmin',
  SYNCUP_DPI: 'SyncUpDPI',
  MOBILE_APP_DEVS: 'SyncUpMADevs',
  UNAUTHORIZED: 'Unauthorized',
  PETS: 'PETS',
  KIDSWATCH: 'WATCH',
  TRACKER: 'TRACKER',
  TLIFE_CMS_ADMINS: 'TLifeCMSAdmins'
};

export const APP_ROLES_MAPPING = {
  PETS: [
    SUA_ROLES.PETS_ADMIN,
    SUA_ROLES.PETS_DEV,
    SUA_ROLES.PETS_PO,
    SUA_ROLES.FIRMWARE_SUPPORT,
    SUA_ROLES.PETS_FIRMWARE_SUPPORT
  ],
  TRACKER: [
    SUA_ROLES.TRACKER_ADMIN,
    SUA_ROLES.TRACKER_DEV,
    SUA_ROLES.TRACKER_PO,
    SUA_ROLES.FIRMWARE_SUPPORT,
    SUA_ROLES.TRACKER_FIRMWARE_SUPPORT
  ],
  WATCH: [
    SUA_ROLES.WATCH_ADMIN,
    SUA_ROLES.WATCH_DEV,
    SUA_ROLES.WATCH_PO,
    SUA_ROLES.FIRMWARE_SUPPORT,
    SUA_ROLES.WATCH_FIRMWARE_SUPPORT
  ],
  DRIVE: [SUA_ROLES.DRIVE_ADMIN, SUA_ROLES.DRIVE_DEV, SUA_ROLES.DRIVE_PO],
  FAMILYLOCATION: [
    SUA_ROLES.FAMILYLOCATION_ADMIN,
    SUA_ROLES.FAMILYLOCATION_PO,
    SUA_ROLES.FAMILYLOCATION_DEV
  ],
  PETS_TRACKER_WATCH_DRIVE: [
    SUA_ROLES.ADMIN,
    SUA_ROLES.PO,
    SUA_ROLES.CARE,
    SUA_ROLES.SYNCUP_DPI, // TO-DO Rrmove SYNCUP_DPI AFter Testing
    SUA_ROLES.MOBILE_APP_DEVS
  ],
  PETS_TRACKER_WATCH: [
    SUA_ROLES.ADMIN,
    SUA_ROLES.MOBILE_APP_DEVS,
    SUA_ROLES.PO,
    SUA_ROLES.CARE,
    SUA_ROLES.FIRMWARE_SUPPORT
  ],
  PETS_TRACKER: [],
  PETS_WATCH: [],
  TRACKER_WATCH: []
};

export const SUA_ENVIRONMENTS = {
  LOCAL: 'local',
  DEVELOPMENT: 'dev',
  STAGING: 'stg',
  PRODUCTION: 'prd'
};

export const SUA_PAGES = {
  SEARCH: {
    path: '/search',
    authorizedRoles: [
      SUA_ROLES.ADMIN,
      SUA_ROLES.SYNCUP_DPI, // TO-DO Remove SYNCUP_DPI AFter Testing
      SUA_ROLES.MOBILE_APP_DEVS,
      SUA_ROLES.CARE,
      SUA_ROLES.PETS_ADMIN,
      SUA_ROLES.PETS_DEV,
      SUA_ROLES.PETS_PO,
      SUA_ROLES.TRACKER_ADMIN,
      SUA_ROLES.TRACKER_DEV,
      SUA_ROLES.TRACKER_PO,
      SUA_ROLES.WATCH_ADMIN,
      SUA_ROLES.WATCH_PO,
      SUA_ROLES.WATCH_DEV,
      SUA_ROLES.DRIVE_ADMIN,
      SUA_ROLES.DRIVE_PO,
      SUA_ROLES.DRIVE_DEV,
      SUA_ROLES.FAMILYLOCATION_ADMIN,
      SUA_ROLES.FAMILYLOCATION_DEV,
      SUA_ROLES.FAMILYLOCATION_PO
    ],
    authorizedProducts: [
      APP_MOBILE.PETS,
      APP_MOBILE.TRACKER,
      APP_MOBILE.WATCH,
      APP_MOBILE.DRIVE,
      APP_MOBILE.FAMILYLOCATION
    ],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    leftNavName: 'Search',
    icon: home
  },
  //TO-DO Uncomment For Home Screen
  /* HOME: {
    path: '/home',
    authorizedRoles: [
      SUA_ROLES.ADMIN,
      SUA_ROLES.SYNCUP_DPI, // TO-DO Remove SYNCUP_DPI AFter Testing
      SUA_ROLES.MOBILE_APP_DEVS,
      SUA_ROLES.CARE,
      SUA_ROLES.PETS_ADMIN,
      SUA_ROLES.PETS_DEV,
      SUA_ROLES.PETS_PO,
      SUA_ROLES.TRACKER_ADMIN,
      SUA_ROLES.TRACKER_DEV,
      SUA_ROLES.TRACKER_PO,
      SUA_ROLES.WATCH_ADMIN,
      SUA_ROLES.WATCH_PO,
      SUA_ROLES.WATCH_DEV,
      SUA_ROLES.DRIVE_ADMIN,
      SUA_ROLES.DRIVE_PO,
      SUA_ROLES.DRIVE_DEV,
      SUA_ROLES.FAMILYLOCATION_ADMIN,
      SUA_ROLES.FAMILYLOCATION_DEV,
      SUA_ROLES.FAMILYLOCATION_PO
    ],
    authorizedProducts: [
      APP_MOBILE.PETS,
      APP_MOBILE.TRACKER,
      APP_MOBILE.WATCH,
      APP_MOBILE.DRIVE,
      APP_MOBILE.FAMILYLOCATION
    ],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    leftNavName: 'Home',
    icon: home, 
  },*/
  APP_VERSIONS: {
    path: '/app-versions',
    authorizedRoles: [
      SUA_ROLES.ADMIN,
      SUA_ROLES.MOBILE_APP_DEVS,
      SUA_ROLES.SYNCUP_DPI, // TO-DO Rrmove SYNCUP_DPI AFter Testing
      SUA_ROLES.PETS_ADMIN,
      SUA_ROLES.PETS_DEV,
      SUA_ROLES.TRACKER_ADMIN,
      SUA_ROLES.TRACKER_DEV,
      SUA_ROLES.WATCH_ADMIN,
      SUA_ROLES.WATCH_DEV,
      SUA_ROLES.DRIVE_ADMIN,
      SUA_ROLES.DRIVE_PO,
      SUA_ROLES.DRIVE_DEV
    ],
    authorizedProducts: [
      APP_MOBILE.PETS,
      APP_MOBILE.TRACKER,
      APP_MOBILE.WATCH,
      APP_MOBILE.FAMILYLOCATION,
      APP_MOBILE.DRIVE
    ],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    leftNavName: 'App Versions',
    leftNavPriority: 2,
    icon: Mobile
  },
  SIMULATORS: {
    path: '/simulators',
    authorizedRoles: [
      SUA_ROLES.ADMIN,
      SUA_ROLES.SYNCUP_DPI, // TO-DO Rrmove SYNCUP_DPI AFter Testing
      SUA_ROLES.MOBILE_APP_DEVS,
      SUA_ROLES.PETS_ADMIN,
      SUA_ROLES.PETS_DEV,
      SUA_ROLES.TRACKER_ADMIN,
      SUA_ROLES.TRACKER_DEV,
      SUA_ROLES.WATCH_ADMIN,
      SUA_ROLES.WATCH_DEV,
      SUA_ROLES.DRIVE_ADMIN,
      SUA_ROLES.DRIVE_PO,
      SUA_ROLES.DRIVE_DEV
    ],
    authorizedProducts: [
      APP_MOBILE.PETS,
      APP_MOBILE.TRACKER,
      APP_MOBILE.WATCH,
      APP_MOBILE.FAMILYLOCATION,
      APP_MOBILE.DRIVE
    ],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    leftNavName: 'Device Simulators',
    leftNavPriority: 3,
    icon: Simulator
  },
  FIRMWARE: {
    path: '/firmware',
    authorizedRoles: [
      SUA_ROLES.ADMIN,
      SUA_ROLES.FIRMWARE_SUPPORT,
      SUA_ROLES.PETS_FIRMWARE_SUPPORT,
      SUA_ROLES.TRACKER_FIRMWARE_SUPPORT,
      SUA_ROLES.PETS_ADMIN,
      SUA_ROLES.TRACKER_ADMIN,
      SUA_ROLES.WATCH_ADMIN,
      SUA_ROLES.WATCH_FIRMWARE_SUPPORT
    ],
    authorizedProducts: [APP_MOBILE.PETS, APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    leftNavName: 'Firmware',
    leftNavPriority: 4,
    icon: acPlug
  },
  MEDIA: {
    defaultPage: '/media',
    path: '/media',
    authorizedRoles: [SUA_ROLES.ADMIN],
    authorizedProducts: [APP_MOBILE.WATCH],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    leftNavName: 'Media',
    leftNavPriority: 5,
    icon: Media
  },
  ANALYTICS: {
    path: '/dashboard',
    authorizedRoles: [
      SUA_ROLES.ADMIN,
      SUA_ROLES.PO,
      SUA_ROLES.PETS_ADMIN,
      SUA_ROLES.PETS_DEV,
      SUA_ROLES.PETS_PO,
      SUA_ROLES.TRACKER_ADMIN,
      SUA_ROLES.TRACKER_DEV,
      SUA_ROLES.TRACKER_PO,
      SUA_ROLES.WATCH_ADMIN,
      SUA_ROLES.WATCH_PO,
      SUA_ROLES.WATCH_DEV,
      SUA_ROLES.DRIVE_ADMIN,
      SUA_ROLES.DRIVE_PO,
      SUA_ROLES.DRIVE_DEV,
      SUA_ROLES.FAMILYLOCATION_ADMIN,
      SUA_ROLES.FAMILYLOCATION_DEV,
      SUA_ROLES.FAMILYLOCATION_PO
    ],
    authorizedProducts: [
      APP_MOBILE.PETS,
      APP_MOBILE.TRACKER,
      APP_MOBILE.WATCH,
      APP_MOBILE.DRIVE,
      APP_MOBILE.FAMILYLOCATION
    ],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    leftNavName: 'Analytics',
    leftNavPriority: 6,
    icon: BarChart,
    subPages: {
      BUSINESS_METRICS: {
        path: '/business-metrics',
        authorizedRoles: [
          SUA_ROLES.ADMIN,
          SUA_ROLES.PO,
          SUA_ROLES.PETS_ADMIN,
          SUA_ROLES.PETS_DEV,
          SUA_ROLES.PETS_PO,
          SUA_ROLES.TRACKER_ADMIN,
          SUA_ROLES.TRACKER_DEV,
          SUA_ROLES.TRACKER_PO,
          SUA_ROLES.WATCH_ADMIN,
          SUA_ROLES.WATCH_PO,
          SUA_ROLES.WATCH_DEV,
          SUA_ROLES.DRIVE_ADMIN,
          SUA_ROLES.DRIVE_PO,
          SUA_ROLES.DRIVE_DEV,
          SUA_ROLES.FAMILYLOCATION_ADMIN,
          SUA_ROLES.FAMILYLOCATION_DEV,
          SUA_ROLES.FAMILYLOCATION_PO
        ],
        authorizedProducts: [
          APP_MOBILE.PETS,
          APP_MOBILE.TRACKER,
          APP_MOBILE.WATCH
        ],
        subPageName: 'Business Metric',
        subPagePriority: 1,
        subMenuIcon: Business_metric
      },
      DASHBOARD: {
        path: '/dashboard',
        defaultPage: '/analytics',
        authorizedRoles: [
          SUA_ROLES.ADMIN,
          SUA_ROLES.PO,
          SUA_ROLES.PETS_ADMIN,
          SUA_ROLES.PETS_DEV,
          SUA_ROLES.PETS_PO,
          SUA_ROLES.TRACKER_ADMIN,
          SUA_ROLES.TRACKER_DEV,
          SUA_ROLES.TRACKER_PO,
          SUA_ROLES.WATCH_ADMIN,
          SUA_ROLES.WATCH_PO,
          SUA_ROLES.WATCH_DEV,
          SUA_ROLES.DRIVE_ADMIN,
          SUA_ROLES.DRIVE_PO,
          SUA_ROLES.DRIVE_DEV,
          SUA_ROLES.FAMILYLOCATION_ADMIN,
          SUA_ROLES.FAMILYLOCATION_DEV,
          SUA_ROLES.FAMILYLOCATION_PO
        ],
        authorizedProducts: [
          APP_MOBILE.PETS,
          APP_MOBILE.TRACKER,
          APP_MOBILE.WATCH,
          APP_MOBILE.DRIVE,
          APP_MOBILE.FAMILYLOCATION
        ],
        subPageName: 'Dashboard',
        subPagePriority: 2,
        subMenuIcon: Dashboard_icon
      },
      TEST_DASHBOARD: {
        path: '/test-dashboard',
        authorizedRoles: [
          SUA_ROLES.ADMIN,
          SUA_ROLES.PO,
          SUA_ROLES.PETS_ADMIN,
          SUA_ROLES.PETS_DEV,
          SUA_ROLES.PETS_PO,
          SUA_ROLES.TRACKER_ADMIN,
          SUA_ROLES.TRACKER_DEV,
          SUA_ROLES.TRACKER_PO,
          SUA_ROLES.WATCH_ADMIN,
          SUA_ROLES.WATCH_PO,
          SUA_ROLES.WATCH_DEV,
          SUA_ROLES.DRIVE_ADMIN,
          SUA_ROLES.DRIVE_PO,
          SUA_ROLES.DRIVE_DEV,
          SUA_ROLES.FAMILYLOCATION_ADMIN,
          SUA_ROLES.FAMILYLOCATION_DEV,
          SUA_ROLES.FAMILYLOCATION_PO
        ],
        authorizedProducts: [
          APP_MOBILE.TRACKER,
          APP_MOBILE.WATCH,
          APP_MOBILE.DRIVE
        ],
        subPageName: 'Test Dashboard',
        subPagePriority: 3,
        subMenuIcon: Test_dashboard
      },
      ERROR_METRICS: {
        path: '/error-metrics',
        authorizedRoles: [
          SUA_ROLES.ADMIN,
          SUA_ROLES.PO,
          SUA_ROLES.PETS_ADMIN,
          SUA_ROLES.PETS_DEV,
          SUA_ROLES.PETS_PO,
          SUA_ROLES.TRACKER_ADMIN,
          SUA_ROLES.TRACKER_DEV,
          SUA_ROLES.TRACKER_PO,
          SUA_ROLES.WATCH_ADMIN,
          SUA_ROLES.WATCH_PO,
          SUA_ROLES.WATCH_DEV,
          SUA_ROLES.DRIVE_ADMIN,
          SUA_ROLES.DRIVE_PO,
          SUA_ROLES.DRIVE_DEV,
          SUA_ROLES.FAMILYLOCATION_ADMIN,
          SUA_ROLES.FAMILYLOCATION_DEV,
          SUA_ROLES.FAMILYLOCATION_PO
        ],
        authorizedProducts: [
          APP_MOBILE.PETS,
          APP_MOBILE.TRACKER,
          APP_MOBILE.WATCH
        ],
        subPageName: 'Error Metric',
        subPagePriority: 4,
        subMenuIcon: Error_metric
      },
      PIER_TICKETING: {
        path: '/pier-ticketing-metrics',
        authorizedRoles: [
          SUA_ROLES.ADMIN,
          SUA_ROLES.PO,
          SUA_ROLES.PETS_ADMIN,
          SUA_ROLES.PETS_DEV,
          SUA_ROLES.PETS_PO,
          SUA_ROLES.TRACKER_ADMIN,
          SUA_ROLES.TRACKER_DEV,
          SUA_ROLES.TRACKER_PO,
          SUA_ROLES.WATCH_ADMIN,
          SUA_ROLES.WATCH_PO,
          SUA_ROLES.WATCH_DEV,
          SUA_ROLES.DRIVE_ADMIN,
          SUA_ROLES.DRIVE_PO,
          SUA_ROLES.DRIVE_DEV,
          SUA_ROLES.FAMILYLOCATION_ADMIN,
          SUA_ROLES.FAMILYLOCATION_DEV,
          SUA_ROLES.FAMILYLOCATION_PO
        ],
        authorizedProducts: [
          APP_MOBILE.TRACKER,
          APP_MOBILE.WATCH,
          APP_MOBILE.DRIVE
        ],
        subPageName: 'Pier Ticketing',
        subPagePriority: 5,
        subMenuIcon: Pier_ticketing
      },
      TICKETING_METRICS: {
        path: '/instabug-metric',
        authorizedRoles: [
          SUA_ROLES.ADMIN,
          SUA_ROLES.PO,
          SUA_ROLES.PETS_ADMIN,
          SUA_ROLES.PETS_DEV,
          SUA_ROLES.PETS_PO,
          SUA_ROLES.TRACKER_ADMIN,
          SUA_ROLES.TRACKER_DEV,
          SUA_ROLES.TRACKER_PO,
          SUA_ROLES.WATCH_ADMIN,
          SUA_ROLES.WATCH_PO,
          SUA_ROLES.WATCH_DEV,
          SUA_ROLES.DRIVE_ADMIN,
          SUA_ROLES.DRIVE_PO,
          SUA_ROLES.DRIVE_DEV,
          SUA_ROLES.FAMILYLOCATION_ADMIN,
          SUA_ROLES.FAMILYLOCATION_DEV,
          SUA_ROLES.FAMILYLOCATION_PO
        ],
        authorizedProducts: [APP_MOBILE.TRACKER],
        subPageName: 'Instabug Metric',
        subPagePriority: 6,
        subMenuIcon: Instabug_metric
      },
      QUERY_TOOL: {
        path: '/query-tool',
        authorizedRoles: [
          SUA_ROLES.ADMIN,
          SUA_ROLES.PO,
          SUA_ROLES.PETS_ADMIN,
          SUA_ROLES.PETS_DEV,
          SUA_ROLES.PETS_PO,
          SUA_ROLES.TRACKER_ADMIN,
          SUA_ROLES.TRACKER_DEV,
          SUA_ROLES.TRACKER_PO,
          SUA_ROLES.WATCH_ADMIN,
          SUA_ROLES.WATCH_PO,
          SUA_ROLES.WATCH_DEV,
          SUA_ROLES.DRIVE_ADMIN,
          SUA_ROLES.DRIVE_PO,
          SUA_ROLES.DRIVE_DEV,
          SUA_ROLES.FAMILYLOCATION_ADMIN,
          SUA_ROLES.FAMILYLOCATION_DEV,
          SUA_ROLES.FAMILYLOCATION_PO
        ],
        authorizedProducts: [APP_MOBILE.WATCH],
        subPageName: 'Query Tool',
        subPagePriority: 7,
        subMenuIcon: Instabug_metric
      },
      OBSERVED_USERS: {
        path: '/observed-users',
        authorizedRoles: [
          SUA_ROLES.ADMIN,
          SUA_ROLES.PO,
          SUA_ROLES.PETS_ADMIN,
          SUA_ROLES.PETS_DEV,
          SUA_ROLES.PETS_PO
        ],
        authorizedProducts: [],
        subPageName: 'Observed Users',
        subPagePriority: 7
      },
      RECENT_DEVICES: {
        path: '/recent-devices',
        authorizedRoles: [
          SUA_ROLES.ADMIN,
          SUA_ROLES.PO,
          SUA_ROLES.PETS_ADMIN,
          SUA_ROLES.PETS_DEV,
          SUA_ROLES.PETS_PO
        ],
        authorizedProducts: [APP_MOBILE.PETS],
        subPageName: 'Recent Devices',
        subPagePriority: 8
      }
    }
  },
  BULK_OPERATIONS: {
    path: '/unclaim',
    authorizedRoles: [SUA_ROLES.ADMIN],
    authorizedProducts: [
      APP_MOBILE.PETS,
      APP_MOBILE.TRACKER,
      APP_MOBILE.WATCH,
      APP_MOBILE.DRIVE
    ],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    leftNavName: 'Bulk Operations',
    leftNavPriority: 7,
    icon: Bulk,
    subPages: {
      BULK_UNCLAIM: {
        path: '/unclaim',
        authorizedRoles: [SUA_ROLES.SUPPLY_CHAIN, SUA_ROLES.ADMIN],
        authorizedProducts: [
          APP_MOBILE.PETS,
          APP_MOBILE.TRACKER,
          APP_MOBILE.WATCH,
          APP_MOBILE.DRIVE
        ],
        subPageName: 'Bulk Unclaim',
        subPagePriority: 1,
        subMenuIcon: Bulk_unclaim
      },
      BULK_NOTIFICATION_AUTOCOMPLETE: {
        defaultPage: '/auto-complete',
        path: '/bulk-notifications',
        authorizedRoles: [SUA_ROLES.ADMIN, SUA_ROLES.CARE, SUA_ROLES.PO],
        authorizedProducts: [
          APP_MOBILE.PETS,
          APP_MOBILE.TRACKER,
          APP_MOBILE.WATCH
        ],
        subPageName: 'Bulk Notification',
        subPagePriority: 2,
        subMenuIcon: Bulk_notification
      },
      BULK_RECOMMISSION: {
        path: '/recommission',
        authorizedRoles: [SUA_ROLES.SUPPLY_CHAIN, SUA_ROLES.ADMIN],
        authorizedProducts: [
          APP_MOBILE.PETS,
          APP_MOBILE.TRACKER,
          APP_MOBILE.WATCH,
          APP_MOBILE.DRIVE
        ],
        subPageName: 'Bulk Recommission',
        subPagePriority: 3,
        subMenuIcon: Bulk_recomission
      },
      BULK_VALIDATE_DEVICES: {
        path: '/validate-device-existence',
        authorizedRoles: [SUA_ROLES.SUPPLY_CHAIN, SUA_ROLES.ADMIN],
        authorizedProducts: [
          APP_MOBILE.TRACKER,
          APP_MOBILE.WATCH,
          APP_MOBILE.DRIVE
        ],
        subPageName: 'Validate Devices',
        subPagePriority: 3,
        subMenuIcon: Validate_device
      }
    }
  },
  PLATFORM_SUPPORT: {
    path: '/platform-support',
    authorizedRoles: [
      SUA_ROLES.ADMIN,
      SUA_ROLES.PO,
      SUA_ROLES.PETS_ADMIN,
      SUA_ROLES.PETS_DEV,
      SUA_ROLES.PETS_PO,
      SUA_ROLES.TRACKER_ADMIN,
      SUA_ROLES.TRACKER_DEV,
      SUA_ROLES.TRACKER_PO,
      SUA_ROLES.WATCH_ADMIN,
      SUA_ROLES.WATCH_PO,
      SUA_ROLES.WATCH_DEV,
      SUA_ROLES.DRIVE_ADMIN,
      SUA_ROLES.DRIVE_PO,
      SUA_ROLES.DRIVE_DEV,
      SUA_ROLES.FAMILYLOCATION_ADMIN,
      SUA_ROLES.FAMILYLOCATION_DEV,
      SUA_ROLES.FAMILYLOCATION_PO
    ],
    authorizedProducts: [
      APP_MOBILE.PETS,
      APP_MOBILE.TRACKER,
      APP_MOBILE.WATCH,
      APP_MOBILE.DRIVE,
      APP_MOBILE.FAMILYLOCATION
    ],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    leftNavName: 'Platform Support',
    leftNavPriority: 8,
    icon: Devices
  },
  AUTH_USERS: {
    path: '/auth-users',
    authorizedRoles: [SUA_ROLES.ADMIN],
    authorizedProducts: [APP_MOBILE.PETS, APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    leftNavName: 'Auth Users',
    leftNavPriority: 9,
    icon: Auth_user
  },
  COMMERCIAL_TRACKER: {
    path: '/commercial-tracker',
    defaultPage: '/dashboard',
    authorizedRoles: [
      SUA_ROLES.TAG_COMMERCIAL_USER,
      SUA_ROLES.TAG_COMMERCIAL_ADMIN
    ],
    authorizedEnvironments: [SUA_ENVIRONMENTS.ALL],
    leftNavName: 'Commercial Tracker',
    leftNavPriority: 10,
    icon: Devices
  },
  COMMERCIAL_TRACKER_USER_MANAGEMENT: {
    path: '/commercial-tracker-user',
    defaultPage: '/management',
    authorizedRoles: [SUA_ROLES.TAG_COMMERCIAL_USER],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    leftNavName: 'User Management',
    leftNavPriority: 11,
    icon: Devices
  },
  GLOSSARY_PAGES: {
    authorizedRoles: [SUA_ROLES.ADMIN, SUA_ROLES.CARE],
    authorizedProducts: [APP_MOBILE.PETS],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    path: '/glossary',
    defaultPage: '/glossary',
    leftNavPriority: 12,
    leftNavName: 'Glossary',
    subPages: {
      SOFTWARE_VERSIONS: {
        path: '/glossary/software-versions',
        defaultPage: '/software-versions',
        subPageName: 'Software Versions',
        subPagePriority: 1,
        authorizedProducts: [APP_MOBILE.PETS]
      },
      ERROR_CODES: {
        path: '/glossary/error-codes',
        defaultPage: '/error-codes',
        subPageName: 'Error Codes',
        subPagePriority: 2,
        authorizedProducts: [APP_MOBILE.PETS]
      },
      BATTERY_STATUS: {
        path: '/glossary/battery-status',
        defaultPage: '/battery-status',
        subPageName: 'Battery Status',
        subPagePriority: 3,
        authorizedProducts: [APP_MOBILE.PETS]
      },
      BREEDS: {
        path: '/breeds',
        defaultPage: '/breeds',
        authorizedRoles: [SUA_ROLES.ADMIN, SUA_ROLES.CARE, SUA_ROLES.PETS],
        authorizedProducts: [APP_MOBILE.PETS],
        subPageName: 'Breeds',
        subPagePriority: 4
      }
    },
    icon: School_Mode
  },
  TEST_HARNESS: {
    authorizedRoles: [
      SUA_ROLES.ADMIN,
      SUA_ROLES.ODM_TRACKER,
      SUA_ROLES.ODM_KIDSWATCH
    ],
    authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING
    ],
    hasSubPages: true,
    path: '/testharness',
    defaultPage: '/testharness',
    leftNavPriority: 13,
    leftNavName: 'Test Harness',
    icons: [
      { product: APP_MOBILE.WATCH, icon: Odm_kidswatch },
      { product: APP_MOBILE.TRACKER, icon: Odm_tracker }
    ],
    subPages: {
      TRACKER: {
        path: '/testharness/TRACKER',
        authorizedRoles: [SUA_ROLES.ADMIN, SUA_ROLES.ODM_TRACKER],
        authorizedProducts: [APP_MOBILE.TRACKER],
        defaultPage: '/TRACKER',
        subPageName: 'ODM Tracker',
        subPagePriority: 1,
        subMenuIcon: Odm_tracker_icon
      },
      KIDSWATCH: {
        path: '/testharness/KIDSWATCH',
        authorizedRoles: [SUA_ROLES.ADMIN, SUA_ROLES.ODM_KIDSWATCH],
        authorizedProducts: [APP_MOBILE.WATCH],
        defaultPage: '/KIDSWATCH',
        subPageName: 'ODM Kidswatch',
        subPagePriority: 2,
        subMenuIcon: Odm_tracker_icon
      }
    }
  },
  PRODUCT_FAQ: {
    authorizedRoles: [
      SUA_ROLES.ADMIN,
      SUA_ROLES.SYNCUP_DPI, // TO-DO Remove SYNCUP_DPI AFter Testing
      SUA_ROLES.MOBILE_APP_DEVS,
      SUA_ROLES.WATCH_ADMIN,
      SUA_ROLES.WATCH_PO
    ],
    authorizedProducts: [APP_MOBILE.WATCH],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    path: '/faq',
    leftNavName: 'Product FAQ',
    leftNavPriority: 14,
    icon: Help
  },
  API_STATUS: {
    authorizedRoles: [
      SUA_ROLES.ADMIN,
      SUA_ROLES.SYNCUP_DPI, // TO-DO Remove SYNCUP_DPI AFter Testing
      SUA_ROLES.MOBILE_APP_DEVS,
      SUA_ROLES.PO,
      SUA_ROLES.PETS_ADMIN,
      SUA_ROLES.PETS_DEV,
      SUA_ROLES.PETS_PO,
      SUA_ROLES.TRACKER_ADMIN,
      SUA_ROLES.TRACKER_DEV,
      SUA_ROLES.TRACKER_PO,
      SUA_ROLES.WATCH_ADMIN,
      SUA_ROLES.WATCH_PO,
      SUA_ROLES.WATCH_DEV,
      SUA_ROLES.DRIVE_ADMIN,
      SUA_ROLES.DRIVE_PO,
      SUA_ROLES.DRIVE_DEV
    ],
    authorizedProducts: [
      APP_MOBILE.PETS,
      APP_MOBILE.TRACKER,
      APP_MOBILE.WATCH,
      APP_MOBILE.DRIVE
    ],
    hasSubPages: false,
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    path: '/api-status',
    leftNavPriority: 15,
    leftNavName: 'API Status',
    icon: Cloud
  },
  DPI_SUPPORT: {
    authorizedRoles: [SUA_ROLES.ADMIN, SUA_ROLES.PO, SUA_ROLES.SYNCUP_DPI],
    authorizedProducts: [
      APP_MOBILE.PETS,
      APP_MOBILE.TRACKER,
      APP_MOBILE.WATCH,
      APP_MOBILE.DRIVE
    ],
    hasSubPages: false,
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    path: '/dpi-support',
    leftNavPriority: 16,
    leftNavName: 'DPI Support',
    icon: DPI
  },
  COAP_SUPPORT: {
    authorizedRoles: [
      SUA_ROLES.ADMIN,
      SUA_ROLES.PO,
      SUA_ROLES.SYNCUP_DPI,
      SUA_ROLES.MOBILE_APP_DEVS
    ],
    authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
    hasSubPages: false,
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    path: '/coap-support',
    leftNavPriority: 17,
    leftNavName: 'COAP Support',
    icon: DPI
  },
  PORTAL_HELP: {
    authorizedRoles: [
      SUA_ROLES.ADMIN,
      SUA_ROLES.SYNCUP_DPI, // TO-DO Rrmove SYNCUP_DPI AFter Testing
      SUA_ROLES.MOBILE_APP_DEVS,
      SUA_ROLES.CARE,
      SUA_ROLES.PETS_ADMIN,
      SUA_ROLES.PETS_DEV,
      SUA_ROLES.PETS_PO,
      SUA_ROLES.TRACKER_ADMIN,
      SUA_ROLES.TRACKER_DEV,
      SUA_ROLES.TRACKER_PO,
      SUA_ROLES.WATCH_ADMIN,
      SUA_ROLES.WATCH_PO,
      SUA_ROLES.WATCH_DEV,
      SUA_ROLES.DRIVE_ADMIN,
      SUA_ROLES.DRIVE_PO,
      SUA_ROLES.DRIVE_DEV,
      SUA_ROLES.FAMILYLOCATION_ADMIN,
      SUA_ROLES.FAMILYLOCATION_DEV,
      SUA_ROLES.FAMILYLOCATION_PO
    ],
    authorizedProducts: [
      APP_MOBILE.PETS,
      APP_MOBILE.TRACKER,
      APP_MOBILE.WATCH,
      APP_MOBILE.DRIVE,
      APP_MOBILE.FAMILYLOCATION
    ],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    path: '/help',
    leftNavPriority: 18,
    icon: Help
  },
  QA_DASHBOARD: {
    authorizedRoles: [SUA_ROLES.ADMIN, SUA_ROLES.PO, SUA_ROLES.MOBILE_APP_DEVS],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT
    ],
    authorizedProducts: [MISC_PRODUCTS.QA_DASHBOARD],
    path: '/qa-dashboard',
    leftNavName: 'QA Dashboard',
    leftNavPriority: 19,
    icon: Dashboard
  },
  INTAKE_FORMS: {
    authorizedRoles: [SUA_ROLES.ADMIN, SUA_ROLES.PO, SUA_ROLES.MOBILE_APP_DEVS],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    authorizedProducts: [MISC_PRODUCTS.INTAKE_FORMS],
    path: '/intake-forms',
    leftNavName: 'Intake Forms',
    leftNavPriority: 20,
    icon: OnboardingIcon,
  },
  CMS_COMPARE: {
    authorizedRoles: [SUA_ROLES.ADMIN, SUA_ROLES.TLIFE_CMS_ADMINS],
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    authorizedProducts: [MISC_PRODUCTS.INTAKE_FORMS],
    path: '/cms-compare',
    defaultPage: '/cms-compare',
    leftNavName: 'CMS Compare',
    leftNavPriority: 21,
    icon: OnboardingIcon,
  },
  OPERATIONS: {
    authorizedRoles: [SUA_ROLES.ADMIN, SUA_ROLES.PO, SUA_ROLES.MOBILE_APP_DEVS],
    authorizedProducts: [APP_MOBILE.DRIVE],
    hasSubPages: false,
    authorizedEnvironments: [
      SUA_ENVIRONMENTS.LOCAL,
      SUA_ENVIRONMENTS.DEVELOPMENT,
      SUA_ENVIRONMENTS.STAGING,
      SUA_ENVIRONMENTS.PRODUCTION
    ],
    path: '/operations',
    leftNavPriority: 22,
    leftNavName: 'Operations',
    icon: Operations
  },
  USER: {
    path: '/search/users/:userId'
  },
  ERROR: {
    path: '/error'
  }
};

export const THEMES = {
  THEME_DEFAULT: 'theme-default',
  THEME_COMMERCIAL: 'theme-commercial'
};

export const ERROR_SCREEN_MESSAGE = {
  UNAUTHORIZED: {
    heading: 'Unauthorized',
    message: "You don't have sufficient privileges to access this resource."
  },
  PAGE_NOT_FOUND: {
    heading: '404',
    message: 'Oops, the page you are looking for is not found.'
  }
};

export const FETCH_STATE = {
  IDLE: 'idle',
  LOADING: 'loading',
  ERROR: 'error',
  COMPLETE: 'complete'
};

export const STATUS_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info'
};

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const mapStyle = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#007ee8' }, { lightness: 80 }]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ color: '#f0f0f0' }, { lightness: 20 }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [{ color: '#ffffff' }, { lightness: 17 }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }, { lightness: 18 }]
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }, { lightness: 16 }]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#f0f0f0' }, { lightness: 21 }]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#dedede' }, { lightness: 21 }]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ saturation: 100 }, { color: '#e20074' }, { lightness: 0 }]
  },
  {
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#f2f2f2' }, { lightness: 19 }]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [{ color: '#fefefe' }, { lightness: 20 }]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }]
  }
];

export const FAQ_VOTES = {
  UPVOTE: 'UPVOTE',
  DOWNVOTE: 'DOWNVOTE'
};

export const FAQ_TEMPLATE = {
  values: [
    'sample category',
    'sample title',
    `<p>Pellentesque habitant morbi tristique senectus et</p><ul><li>Lorem ipsum dolor.</li><li>Aliquam tincidunt mauris eu risus.</li><li>Vestibulum auctor dapibus neque.</li></ul>`
  ]
};

export const LOGS_FILTER_OPTIONS = ['Call', 'SMS', 'VideoCall'];
export const DEFAULT_LOGS_FILTER_OPTIONS = 'VIDEOCALL';

export const PAGE_SIZE_OPTIONS = [100, 200, 500, 1000, 5000];

export const NOTIFICATIONS_PAGE_SIZE_OPTIONS = [15, 30, 50, 75, 100];
export const LOGS_PAGE_SIZE_OPTIONS = [15, 30, 50, 100];
export const MEDIA_PAGE_SIZE_OPTIONS = [15, 30, 100];

export const FIRMWARE_PAGE_SIZE_OPTIONS = [15, 30, 100];
export const API_STATUS_PAGE_SIZE_OPTIONS = [15, 30, 50, 75, 100];

export const APIACTION = {
  APIDETAILS: 'API Details',
  CURLREQUEST: 'Curl Request',
  REPONSESTRUCTURE: 'Response Structure',
  TRYITOUT: 'Try It Out'
};

export const GRID = {
  COLUMN_FULL: 'column_1',
  COLUMN_HALF: 'column_2',
  COLUMN_THIRD: 'column_3',
  COLUMN_FORTH: 'column_4'
};

export const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const DIMENSIONS = {
  MOBILE_WIDTH: 767,
  IPAD_WIDTH: 768,
  HYBRID_WIDTH: 991
};

export const APP_LANGUAGE = {
  ENGLISH: 'en',
  SPANISH: 'es'
};

export const IS_AZURE_AUTH = true;
